import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../utils/Api";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import ViewOutletOrder from "./ViewOutletOrder";
import { AppConfig } from "../../config";
import ProductList from "./ProductList";
import { format, render, cancel, register } from "timeago.js";
import ModalOrderTracking from "./ModalOrderTracking";
import ChooseBrandOutletModal from "./ChooseBrandOutletModal";
import ModalUserDetails from "./ModalUserDetails";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { useParams } from "react-router-dom";

import TrackOrder from "./TrackOrder";
import InfiniteScroll from "react-infinite-scroll-component";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Orders(props: any) {
  const [all, SetAll] = useState(true);
  const [seller_modal, setSellerModal] = useState({
    is_open: false,

  });
  const location = useLocation();
  const [id_value, SetIdValue] = useState(useParams());
  const [pending, SetPending] = useState(false);
  const [process, SetProcess] = useState(false);
  const [tab_filter, SertTabFilter] = useState([
    { label: "All", value: 1 },
    { label: "New", value: 2 },
    { label: "Processing", value: 3 },
    { label: "Completed", value: 4 },

  ]);

  const dropdownOptions = [
    { label: "Accepted", value: 5 },
    { label: "Rejected", value: 6 },
    { label: "Active", value: 7 },
    { label: "Inactive", value: 8 },
    { label: "Success", value: 9 },
    { label: "Failed", value: 10 },
    { label: "Cancelled", value: 11 },
  ];
  const [brand_name, SetBrandName] = useState("");
  const [outlet_name, SetOutletName] = useState("");
  const [shipped, SetShipped] = useState(false);
  const [products_diet_type, SetProductsDietType]: any = useState({});
  const [isOn, setIsOn] = useState(false);

  const [order_tracking_modal, SetOrderTrackingModal] = useState({
    is_open: false,
    lat: "",
    lon: "",
    id: "",
    delivery_id: "",
    delivery_index: "",
    shipment_index: "",
    order_details: "",
  });
  const [filter_data, setFilterData] = useState("");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false)

  const [timeoutId, setTimeoutId] = useState(null);

  const [view_modal, SetViewModal] = useState({
    is_open: false,
    index: "",
    data: {},
  });
  const [user_modal, SetUserModal] = useState({
    is_open: false,
    id: ""
  });
  const [view_track_modal, SetViewTrackModal] = useState({
    is_open: false,
    index: "",
    data: {},
  });
  const [abortController, setAbortController] = useState<AbortController | null>(
    null
  );
  // /////
  const [order_data, SetOrderData] = useState([]);
  const [page_no, SetPageNo] = useState(1);
  const [order_status, SetOrderStatus] = useState(1);

  const [next_page, SetNextPage] = useState(false);

  const [visible_content, SetVisibleContent] = useState(false);
  const [brand_id, SetBrandId] = useState("")
  const [outlet_id, SetOutletId] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    let pass_data = {
      order_status: 1,
      seller_brand_id: brand_id,
      seller_outlet_id: outlet_id,
      sort: "",
      page_no: 1,
      demo: isOn,
    };
    get_orders(pass_data, filter_data);
    return () => {
      SetOrderData([])
      SetPageNo(1); // Resets the page number on component unmount or dependency change
    };
  }, [location]);

  function ViewModalOnClose(data: any) {
    SetViewModal((prevValue) => {
      prevValue.is_open = false;
      return { ...prevValue };
    });
  }

  const Spinner = () => <div className="loader-spinner"></div>;
  function openMap(dir_lat: any, dir_lng: any) {
    window.open(
      "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
      "_blank"
    );

    // document.location.href =   "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
    // "_blank"
  }

  function openPickDropMap(dir_lat: any, dir_lng: any, des_lat: any, des_lng: any) {
    // window.open(
    //   "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
    //   "_blank"
    // );

    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${dir_lat},${dir_lng}&destination=${des_lat},${des_lng}&travelmode=driving`;
    window.open(googleMapsUrl, "_blank");

    // document.location.href =   "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
    // "_blank"
  }


  function ViewTrackModalOnClose(data: any) {
    SetViewTrackModal((prevValue) => {
      prevValue.is_open = false;
      return { ...prevValue };
    });
  }
  const OwnAccordian = (acc_props: any) => {
    const [is_open, SetIsopen] = useState(false);
    return (
      <>
        {acc_props.from == "shipment" ? (
          <>
            <div
              className="d-flex pt-3"
              onClick={() => {
                SetIsopen(!is_open);
              }}
            >
              <h6 className="text-dark ">{acc_props.heading}</h6>

              {is_open ? (
                <MaterialIcons
                  name="keyboard-arrow-down"
                  size={24}
                  color="black"
                />
              ) : (
                <MaterialIcons
                  name="keyboard-arrow-up"
                  size={24}
                  color="black"
                />
              )}
            </div>
            <div className="">{is_open ? acc_props.children : null}</div>
          </>
        ) : (
          <>
            <div
              className="d-flex pt-3"
              onClick={() => {
                SetIsopen(!is_open);
              }}
            >
              <h6 className="text-dark ">{acc_props.heading}</h6>
              <div className="ms-auto">
                {is_open ? (
                  <MaterialIcons
                    name="keyboard-arrow-down"
                    size={24}
                    color="black"
                  />
                ) : (
                  <MaterialIcons
                    name="keyboard-arrow-up"
                    size={24}
                    color="black"
                  />
                )}
              </div>
            </div>
            <div className="">{is_open ? acc_props.children : null}</div>
          </>
        )}{" "}
      </>
    );
  };

  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;
    setIsOn(isChecked);

    if (isChecked) {
      // When switch is ON
      console.log('Switch is ON');
      let pass_data = {
        order_status: order_status,
        seller_brand_id: brand_id,
        seller_outlet_id: outlet_id,
        sort: "",
        page_no: 1,
        demo: true,
      };
      get_orders(pass_data, filter_data);
      SetPageNo(1)
    } else {
      // When switch is OFF
      let pass_data = {
        order_status: order_status,
        seller_brand_id: brand_id,
        seller_outlet_id: outlet_id,
        sort: "",
        page_no: 1,
        demo: false,
      };
      SetPageNo(1)

      get_orders(pass_data, filter_data);
      // Call other logic if needed
    }
  };


  async function get_orders(get_data: any, search: any) {
    console.log(id_value)

    if (!get_data.load_more && !get_data.refresh) {
      SetVisibleContent(false);
    }

    {
      get_data.refresh && setLoader(true);
    }
    let pass_data = {
      get: {
        order_status: props.type,
        page: get_data.page_no,
        search: search,
        sort: get_data.sort,
        seller_brand_id: get_data.seller_brand_id,
        seller_outlet_id: get_data.seller_outlet_id,
        demo_orders: get_data.demo ? "1" : ""
      },
    };
    let response: any = await api("/orders/get", pass_data);
    if (response.status_code == 200) {
      setLoader(false);
      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        if (get_data.load_more) {
          SetOrderData((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetOrderData(response.response.data);
        }
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }

      if (response.response?.ordered_product_spec) {
        SetProductsDietType((prevValue: any) => {
          return { ...prevValue, ...response?.response?.ordered_product_spec };
        });
      }
      setLoading(false);

    }
  }

  async function activate_order(get_data: any) {
    let pass_data = {
      post: {
        order_id: get_data.id,
      },
    };
    let response: any = await api("/orders/activate_store_order", pass_data);
    if (response.status_code == 200) {
      SetPageNo((prevalue: any) => {
        let pass_data = {
          order_status: 1,
          seller_brand_id: brand_id,
          seller_outlet_id: outlet_id,
          sort: "",
          page_no: 1,
          demo: isOn,
        };
        get_orders(pass_data, filter_data);
        prevalue = 1;
        return prevalue;
      });
    }
  }

  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call your API with the search value
      let pass_data = {
        sort: "",
        page_no: 1,
        order_status: order_status,
        seller_brand_id: brand_id,
        seller_outlet_id: outlet_id,
        demo: isOn,

      };
      SetPageNo(1);

      get_orders(pass_data, searchValue);
    }, 1000); // 5 milliseconds delay

    setTimeoutId(newTimeoutId);
  };

  async function call_del_func(id: any, del_id: any, d_id: any) {
    let pass_data = {
      get: {
        order_id: id,
        callto: del_id,
        agent_order_id: d_id,
      },
    };
    let data_res: any = await api("/orders/call", pass_data);
    if ((data_res.status_code = 200)) {
      if (data_res?.response?.phone_number) {
        // window.open("tel:" + data_res?.response?.phone_number);
        document.location.href = "tel:" + data_res?.response?.phone_number;
      }
    }
  }
  const OrderSkeleton = () => {
    return (
      <div className="card p-3 cursor">
        <div className="row align-items-center">
          <div className="col-6 pb-3">
            <Skeleton height={10} width={50} />
          </div>
          <div className="col-6 text-end">
            <Skeleton height={30} width={100} />
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-6 pb-2">
            <Skeleton height={10} width={50} />
            <Skeleton height={10} width={120} />
          </div>
          <div className="col-6 pb-2 text-end">
            <Skeleton height={10} width={120} />
            <Skeleton height={10} width={100} />
          </div>
        </div>

        <div className="pb-3 pt-2">
          <hr className="text-gray m-0" />
        </div>

        <div className=" pb-2">
          <Skeleton height={10} width={120} />
        </div>

        <div className="py-2">
          <div className="d-flex pb-3">
            <Skeleton height={18} width={18} />
            <div className="ps-2">
              <Skeleton height={10} width={120} />
              <Skeleton height={10} width={180} />
            </div>
          </div>
          <div className="d-flex pb-3">
            <Skeleton height={18} width={18} />
            <div className="ps-2">
              <Skeleton height={10} width={120} />
              <Skeleton height={10} width={180} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  async function load_more(page_value) {
    let page_no_value = page_no + 1;

    SetPageNo(page_no_value);

    console.log(id_value);

    let pass_data: any;

    if (id_value.hasOwnProperty("brand_id")) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
          view: view_type,
          page: page_no_value,
        },
      };
    } else {
      pass_data = {
        get: {
          type: "all",
          view: view_type,
          page: page_no_value,
        },
      };
    }

    let response: any = await api("/outlet/orders", pass_data);

    if (response?.status_code == 200) {
      console.log("/brand/add_brand_product_list :", response);

      SetOrderData((prevValue) => {
        response.response.orders.map((item) => {
          prevValue.push(item);
        });
        return [...prevValue];
      });
      if (response?.response?.ordered_product_spec) {
        SetProductsDietType((prevValue: any) => {
          return { ...prevValue, ...response?.response?.ordered_product_spec };
        });
      }
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response?.response?.next_page);
        // SetPageNo((prevalue) => {
        //   prevalue = prevalue + 1;
        //   return prevalue;
        // });
      }
    }
  }

  return (
    <div className="">
      {/* Header */}
      <div className="head-bar-order row">
        <div className="d-flex">
          <div className="">
            <h5 className="  mb-2 me-2">Orders</h5>
            <div className="  mb-2">{brand_name}</div>

            <div className="  mb-2">{outlet_name}</div>

          </div>
          <div className="ms-auto">
            <div className="d-flex">
              <button className="btn btn-primary me-2 btn-choose "
                onClick={() =>
                  setSellerModal((prevValue: any) => ({
                    ...prevValue,
                    is_open: true,
                  }))}>Choose Seller</button>
              <div className="input-icons form-group p-2 search-order">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search here ..."
                  onChange={handleInputChange}
                />
                <div className="cursor px-2 border-left">
                  <Ionicons name="md-search" size={16} color="#ccc" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-filter d-flex d-lg-none mt-2 flex-row align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div
              class="">Show Demo Order</div>
            <div class="form-check form-switch ms-2">
              <input class="form-check-input" type="checkbox" id="demoOrderSwitch"
                checked={isOn}
                onChange={handleSwitchChange} />
              <label class="form-check-label" for="demoOrderSwitch"></label>
            </div>
          </div>
          {/* filter */}

          {loader ? (
            <button className="btn btn-white dropdown-toggle d-flex align-items-cetner">
              <Spinner />
            </button>
          ) : (
            <button
              className="btn btn-white dropdown-toggle d-flex align-items-cetner"
              onClick={() => {
                // SetAll(true);
                // SetPending(false);
                // SetProcess(false);
                // SetShipped(false);
                let pass_data: any = {
                  order_status: order_status,
                  seller_brand_id: brand_id,
                  seller_outlet_id: outlet_id,
                  sort: "",
                  refresh: true,
                  page_no: 1,
                  demo: isOn,

                };
                SetPageNo(1);
                get_orders(pass_data, filter_data);
              }}
            >
              <img
                src={require("../../assets/icon/refresh.png")}
                className="icon-20px"
              />
            </button>
          )}

          <div className="dropdown">
            <button
              className="btn btn-white dropdown-toggle d-flex align-items-cetner"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={require("../../assets/icon/filter.png")}
                className="icon-14px"
              />
              <p className="mb-0 text-dark d-md-block d-sm-none ps-2">Filter</p>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => {
                      SetAll(false);

                      SetPending(false);
                      SetProcess(false);
                      SetShipped(false);
                      SetOrderStatus(5);
                      SetPageNo(1);
                      let pass_data = {
                        order_status: 5,
                        seller_brand_id: brand_id,
                        seller_outlet_id: outlet_id,
                        sort: "",
                        page_no: 1,
                        demo: isOn,

                      };
                      get_orders(pass_data, filter_data);
                    }}
                  >
                    <p className="mb-0 p-2">Accepted</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => {
                      SetAll(false);

                      SetPending(false);
                      SetProcess(false);
                      SetShipped(false);
                      SetOrderStatus(6);
                      SetPageNo(1);
                      let pass_data = {
                        order_status: 6,
                        seller_brand_id: brand_id,
                        seller_outlet_id: outlet_id,
                        sort: "",
                        page_no: 1,
                        demo: isOn,

                      };
                      get_orders(pass_data, filter_data);
                    }}
                  >
                    <p className="mb-0 p-2">Rejected</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => {
                      SetAll(false);

                      SetPending(false);
                      SetProcess(false);
                      SetShipped(false);
                      SetOrderStatus(7);
                      SetPageNo(1);
                      let pass_data = {
                        order_status: 7,
                        seller_brand_id: brand_id,
                        seller_outlet_id: outlet_id,
                        sort: "",
                        page_no: 1,
                        demo: isOn,

                      };
                      get_orders(pass_data, filter_data);
                    }}
                  >
                    <p className="mb-0 p-2">Active</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => {
                      SetAll(false);

                      SetPending(false);
                      SetProcess(false);
                      SetShipped(false);
                      SetOrderStatus(8);
                      SetPageNo(1);
                      let pass_data = {
                        order_status: 8,
                        seller_brand_id: brand_id,
                        seller_outlet_id: outlet_id,
                        sort: "",
                        page_no: 1,
                        demo: isOn,

                      };
                      get_orders(pass_data, filter_data);
                    }}
                  >
                    <p className="mb-0 p-2">Inavtive</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => {
                      SetAll(false);

                      SetPending(false);
                      SetProcess(false);
                      SetShipped(false);
                      SetOrderStatus(9);
                      SetPageNo(1);
                      let pass_data = {
                        order_status: 9,
                        seller_brand_id: brand_id,
                        seller_outlet_id: outlet_id,
                        sort: "",
                        page_no: 1,
                        demo: isOn,

                      };
                      get_orders(pass_data, filter_data);
                    }}
                  >
                    <p className="mb-0 p-2">Success</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => {
                      SetAll(false);

                      SetPending(false);
                      SetProcess(false);
                      SetShipped(false);
                      SetOrderStatus(10);
                      SetPageNo(1);
                      let pass_data = {
                        order_status: 10,
                        seller_brand_id: brand_id,
                        seller_outlet_id: outlet_id,
                        sort: "",
                        page_no: 1,
                        demo: isOn,

                      };
                      get_orders(pass_data, filter_data);
                    }}
                  >
                    <p className="mb-0 p-2">Failed</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => {
                      SetAll(false);

                      SetPending(false);
                      SetProcess(false);
                      SetShipped(false);
                      SetOrderStatus(11);
                      SetPageNo(1);
                      let pass_data = {
                        order_status: 11,
                        seller_brand_id: brand_id,
                        seller_outlet_id: outlet_id,
                        sort: "",
                        page_no: 1,
                        demo: isOn,

                      };
                      get_orders(pass_data, filter_data);
                    }}
                  >
                    <p className="mb-0 p-2">Cancelled</p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="dropdown">
            <button
              className="btn btn-white dropdown-toggle d-flex align-items-cetner"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={require("../../assets/icon/funnel.png")}
                className="icon-14px"
              />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => {
                      SetPageNo(1);
                      let pass_data = {
                        order_status: order_status,
                        seller_brand_id: brand_id,
                        seller_outlet_id: outlet_id,
                        sort: "asc",
                        page_no: 1,
                        demo: isOn,

                      };
                      get_orders(pass_data, filter_data);
                    }}
                  >
                    <p className="mb-0 p-2">Oldest</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => {
                      SetPageNo(1);
                      let pass_data = {
                        order_status: order_status,
                        seller_brand_id: brand_id,
                        seller_outlet_id: outlet_id,
                        sort: "desc",
                        page_no: 1,
                        demo: isOn,

                      };
                      get_orders(pass_data, filter_data);
                    }}
                  >
                    <p className="mb-0 p-2">Newest</p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-1 d-flex align-items-center bg-fff px-2 md-px-3 ">
          <div className="d-flex align-items-center scrollX">
            {tab_filter.map((tab, tab_index) => (

              <Link
                key={tab_index}
                type="button"
                className={
                  props?.type == tab.value
                    ? "btn btn-text header-tap me-1 px-3 pb-2 center"
                    : "btn btn-text text-darkgray me-1 px-3 pb-2 "
                }
                to={
                  (

                    "/orders/" +
                    tab.value)

                }
              >
                {tab.label}
              </Link>
            ))}
            {/* <button
              type="button"
              className={
                all
                  ? "btn btn-text header-tap me-1 px-3 "
                  : "btn btn-text text-black me-1 px-3"
              }
              onClick={() => {
                SetAll(!all);
                SetPending(false);
                SetProcess(false);
                SetShipped(false);

                SetOrderStatus(1);
                SetPageNo(1);
                let pass_data = {
                  order_status: 1,
                  seller_brand_id: brand_id,
                  seller_outlet_id: outlet_id,
                  sort: "",
                  page_no: 1,
      demo: isOn,

                };
                get_orders(pass_data, filter_data);
              }}
            >
              All
            </button>

            <button
              type="button"
              className={
                pending
                  ? "btn btn-text header-tap me-1 px-3 "
                  : "btn btn-text text-black me-1 px-3"
              }
              onClick={() => {
                SetAll(false);
                SetPending(!pending);
                SetProcess(false);
                SetShipped(false);

                SetOrderStatus(2);
                SetPageNo(1);
                let pass_data = {
                  order_status: 2,
                  seller_brand_id: brand_id,
                  seller_outlet_id: outlet_id,
                  sort: "",
                  page_no: 1,
      demo: isOn,

                };
                get_orders(pass_data, filter_data);
              }}
            >
              New
            </button>

            <button
              type="button"
              className={
                process
                  ? "btn btn-text header-tap me-1 px-3 "
                  : "btn btn-text text-black me-1 px-3"
              }
              onClick={() => {
                SetAll(false);
                SetProcess(!process);
                SetShipped(false);
                if (abortController) {
                  abortController.abort();
                }
            
                // Create a new AbortController for the fresh request
                const newController = new AbortController();
                setAbortController(newController);
                SetOrderStatus(3);
                SetPageNo(1);
                SetPending(false);
                let pass_data = {
                  order_status: 3,
                  seller_brand_id: brand_id,
                  seller_outlet_id: outlet_id,
                  sort: "",
                  page_no: 1,
      demo: isOn,
      load_more:false,

                };
                get_orders(pass_data, filter_data);
              }}
            >
              Processing
            </button>

            <button
              type="button"
              className={
                shipped
                  ? "btn btn-text header-tap me-1 px-3 "
                  : "btn btn-text text-black me-1 px-3"
              }
              onClick={() => {
                SetAll(false);
                SetShipped(!shipped);

                SetPending(false);
                SetProcess(false);
                SetOrderStatus(4);
                SetPageNo(1);
                let pass_data = {
                  order_status: 4,
                  seller_brand_id: brand_id,
                  seller_outlet_id: outlet_id,
                  sort: "",
                  page_no: 1,
      demo: isOn,

                };
                get_orders(pass_data, filter_data);
              }}
            >
              Completed
            </button> */}
          </div>
          {/* <div className="mobile-filter d-flex"> */}
          {/* <div className="mobile-filter d-sm-none d-lg-block mt-2 d-flex flex-column"> */}
          <div className="mobile-filter d-none d-lg-flex mt-2 flex-row align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="me-1">Show Demo Order</div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="demoOrderSwitch"
                  checked={isOn}
                  onChange={handleSwitchChange} />
                <label class="form-check-label" for="demoOrderSwitch"></label>
              </div>
            </div>
            {/* filter */}

            {loader ? (
              <button className="btn btn-white dropdown-toggle d-flex align-items-cetner">
                <Spinner />
              </button>
            ) : (
              <button
                className="btn btn-white dropdown-toggle d-flex align-items-cetner"
                onClick={() => {
                  // SetAll(true);
                  // SetPending(false);
                  // SetProcess(false);
                  // SetShipped(false);
                  let pass_data: any = {
                    order_status: order_status,
                    seller_brand_id: brand_id,
                    seller_outlet_id: outlet_id,
                    sort: "",
                    refresh: true,
                    page_no: 1,
                    demo: isOn,

                  };
                  SetPageNo(1);
                  get_orders(pass_data, filter_data);
                }}
              >
                <img
                  src={require("../../assets/icon/refresh.png")}
                  className="icon-20px"
                />
              </button>
            )}

            <div className="dropdown">
              <button
                className="btn btn-white dropdown-toggle d-flex align-items-cetner"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={require("../../assets/icon/filter.png")}
                  className="icon-14px"
                />
                <p className="mb-0 text-dark d-md-block d-sm-none ps-2">Filter</p>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

              {dropdownOptions.map((tab, tab_index) => (
<li>
<Link
  key={tab_index}
  type="button"
  className="p-2"
  to={
    (

      "/orders/" +
      tab.value)

  }
>
  {tab.label}
</Link>
</li>
))}
                {/* <li>
                  <a className="dropdown-item" href="#">
                    <div
                      onClick={() => {
                        SetAll(false);

                        SetPending(false);
                        SetProcess(false);
                        SetShipped(false);
                        SetOrderStatus(5);
                        SetPageNo(1);
                        let pass_data = {
                          order_status: 5,
                          seller_brand_id: brand_id,
                          seller_outlet_id: outlet_id,
                          sort: "",
                          page_no: 1,
                          demo: isOn,

                        };
                        get_orders(pass_data, filter_data);
                      }}
                    >
                      <p className="mb-0 p-2">Accepted</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <div
                      onClick={() => {
                        SetAll(false);

                        SetPending(false);
                        SetProcess(false);
                        SetShipped(false);
                        SetOrderStatus(6);
                        SetPageNo(1);
                        let pass_data = {
                          order_status: 6,
                          seller_brand_id: brand_id,
                          seller_outlet_id: outlet_id,
                          sort: "",
                          page_no: 1,
                          demo: isOn,

                        };
                        get_orders(pass_data, filter_data);
                      }}
                    >
                      <p className="mb-0 p-2">Rejected</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <div
                      onClick={() => {
                        SetAll(false);

                        SetPending(false);
                        SetProcess(false);
                        SetShipped(false);
                        SetOrderStatus(7);
                        SetPageNo(1);
                        let pass_data = {
                          order_status: 7,
                          seller_brand_id: brand_id,
                          seller_outlet_id: outlet_id,
                          sort: "",
                          page_no: 1,
                          demo: isOn,

                        };
                        get_orders(pass_data, filter_data);
                      }}
                    >
                      <p className="mb-0 p-2">Active</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <div
                      onClick={() => {
                        SetAll(false);

                        SetPending(false);
                        SetProcess(false);
                        SetShipped(false);
                        SetOrderStatus(8);
                        SetPageNo(1);
                        let pass_data = {
                          order_status: 8,
                          seller_brand_id: brand_id,
                          seller_outlet_id: outlet_id,
                          sort: "",
                          page_no: 1,
                          demo: isOn,

                        };
                        get_orders(pass_data, filter_data);
                      }}
                    >
                      <p className="mb-0 p-2">Inavtive</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <div
                      onClick={() => {
                        SetAll(false);

                        SetPending(false);
                        SetProcess(false);
                        SetShipped(false);
                        SetOrderStatus(9);
                        SetPageNo(1);
                        let pass_data = {
                          order_status: 9,
                          seller_brand_id: brand_id,
                          seller_outlet_id: outlet_id,
                          sort: "",
                          page_no: 1,
                          demo: isOn,

                        };
                        get_orders(pass_data, filter_data);
                      }}
                    >
                      <p className="mb-0 p-2">Success</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <div
                      onClick={() => {
                        SetAll(false);

                        SetPending(false);
                        SetProcess(false);
                        SetShipped(false);
                        SetOrderStatus(10);
                        SetPageNo(1);
                        let pass_data = {
                          order_status: 10,
                          seller_brand_id: brand_id,
                          seller_outlet_id: outlet_id,
                          sort: "",
                          page_no: 1,
                          demo: isOn,

                        };
                        get_orders(pass_data, filter_data);
                      }}
                    >
                      <p className="mb-0 p-2">Failed</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <div
                      onClick={() => {
                        SetAll(false);

                        SetPending(false);
                        SetProcess(false);
                        SetShipped(false);
                        SetOrderStatus(11);
                        SetPageNo(1);
                        let pass_data = {
                          order_status: 11,
                          seller_brand_id: brand_id,
                          seller_outlet_id: outlet_id,
                          sort: "",
                          page_no: 1,
                          demo: isOn,

                        };
                        get_orders(pass_data, filter_data);
                      }}
                    >
                      <p className="mb-0 p-2">Cancelled</p>
                    </div>
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="dropdown">
              <button
                className="btn btn-white dropdown-toggle d-flex align-items-cetner"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={require("../../assets/icon/funnel.png")}
                  className="icon-14px"
                />
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a className="dropdown-item" href="#">
                    <div
                      onClick={() => {
                        SetPageNo(1);
                        let pass_data = {
                          order_status: order_status,
                          seller_brand_id: brand_id,
                          seller_outlet_id: outlet_id,
                          sort: "asc",
                          page_no: 1,
                          demo: isOn,

                        };
                        get_orders(pass_data, filter_data);
                      }}
                    >
                      <p className="mb-0 p-2">Oldest</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <div
                      onClick={() => {
                        SetPageNo(1);
                        let pass_data = {
                          order_status: order_status,
                          seller_brand_id: brand_id,
                          seller_outlet_id: outlet_id,
                          sort: "desc",
                          page_no: 1,
                          demo: isOn,

                        };
                        get_orders(pass_data, filter_data);
                      }}
                    >
                      <p className="mb-0 p-2">Newest</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* body */}
      {visible_content ?  (
      order_data && order_data.length > 0 ?
      <div className="px-3 sm-mt-2 sm-pt-4">
        <div className="mt-4 mb-4">
          <InfiniteScroll
            dataLength={order_data.length}
            next={() => {
              console.log("hi")
              let pass_data = {
                order_status: order_status,
                seller_brand_id: brand_id,
                seller_outlet_id: outlet_id,
                sort: "",
                load_more: true,

                page_no: page_no,
                demo: isOn,

              };
              // load_more(pass_data);
              
              get_orders(pass_data, filter_data);
            }}
            hasMore={next_page}
            loader={
              <div className="center m-5">
                <div className="lds-dual-ring"></div>
                <p className=" small ps-2">Just a second ...</p>
              </div>
            }
          >
          
                <div className="row mb-4">
                  {order_data?.map((ord_ele: any, ord_index: any) => (
                    <div className="col-md-4 col-12 mb-3" key={ord_index}>
                      {/* target="_blank" */}
                      {/* <div className="card p-3 cursor">
                          <div className="row align-items-center">
                            <div className="col-6 pb-3">
                              <p className="text-dark fw-bold">
                                {ord_ele.sector}
                              </p>
                            </div>
                            <div className="col-6 end pb-3">
                              <button
                                className="btn btn-prime btn-sm"
                             
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 pb-2">
                              <p className="text-dark fw-500">#{ord_ele.id}</p>
                            </div>
                            <div className="col-6 end pb-2">
                              <p className="extra-small">{ord_ele.date}</p>
                            </div>
                            <div className="col-6 pb-2 d-flex align-items-center">
                              <img
                                src={require("../../assets/icon/scooter.png")}
                                className="icon-14px"
                              />
                              <p className="ps-2 text-gray">
                                {ord_ele.delivery_type}
                              </p>
                            </div>
                            <div className="col-6 end pb-2">
                              <FontAwesome
                                name="circle"
                                size={10}
                                color={
                                  // ord_ele.status == "Accepted"
                                  //   ? "blue"
                                  //   : ord_ele.status == "Completed"
                                  //   ? "green"
                                  //   : ord_ele.status == "Order Placed"
                                  //   ? "orange"
                                  //   : "red"
                                  ord_ele.status_color
                                }
                              />
                              <p
                                // className={
                                //   ord_ele.status == "Accepted"
                                //     ? "text-blue ps-2"
                                //     : ord_ele.status == "Completed"
                                //     ? "text-green ps-2"
                                //     : ord_ele.status == "Order Placed"
                                //     ? "text-orange ps-2"
                                //     : "text-red ps-2"
                                // }
                                className="ps-2"
                                style={{color:ord_ele.status_color}}
                              >
                                {ord_ele.status}
                              </p>
                            </div>
                          </div>

                          <div className="pb-3 pt-2">
                            <hr className="text-gray m-0" />
                          </div>

                          <div className=" pb-2">
                            <p className=" text-dark fs-7 fw-bold">
                              {ord_ele.customer?.name}
                            </p>
                          </div>

                          <div className="py-2">
                            {ord_ele.sector_id == "4" ? (
                              <div className="">
                                <div className="d-flex pb-3">
                                  <img
                                    src={require("../../assets/icon/up-arrow.png")}
                                    className="icon-18px"
                                  />
                                  <div className="ps-2">
                                    <p className="text-dark pb-1">Pickup</p>
                                    <p className="text-dark fw-bold">
                                      {
                                        ord_ele?.customer?.pickup_address
                                          ?.firstname
                                      }{" "}
                                      {
                                        ord_ele?.customer?.pickup_address
                                          ?.lastname
                                      }
                                    </p>
                                    {ord_ele?.customer?.pickup_address
                                      ?.address ? (
                                      <p className="pt-1">
                                        {
                                          ord_ele?.customer?.pickup_address
                                            ?.address
                                        }
                                      </p>
                                    ) : (
                                      <p className="pt-1">
                                        {
                                          ord_ele?.customer?.pickup_address
                                            ?.map_address
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <img
                                    src={require("../../assets/icon/down.png")}
                                    className="icon-18px"
                                  />
                                  <div className="ps-2">
                                    <p className="text-dark pb-1">Delivery</p>
                                    <p className="text-dark fw-bold">
                                      {
                                        ord_ele?.customer?.delivery_address
                                          ?.firstname
                                      }{" "}
                                      {
                                        ord_ele?.customer?.delivery_address
                                          ?.lastname
                                      }
                                    </p>
                                    {ord_ele?.customer?.delivery_address
                                      ?.address ? (
                                      <p className="pt-1">
                                        {
                                          ord_ele?.customer?.delivery_address
                                            ?.address
                                        }
                                      </p>
                                    ) : (
                                      <p className="pt-1">
                                        {
                                          ord_ele?.customer?.delivery_address
                                            ?.map_address
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="d-flex">
                                  <img
                                    src={require("../../assets/icon/seller.png")}
                                    className="icon-18px"
                                  />
                                  <div className="ps-2">
                                    <p className="text-dark pb-1">
                                      {ord_ele.seller?.brand_name}
                                    </p>
                                    <p className="text-gray">
                                      {ord_ele.seller?.outlet?.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {!ord_ele.active ? (
                            <>
                              <button
                                className="btn btn-orange ms-auto btn-sm px-3"
                                onClick={() => {
                                  activate_order(ord_ele);
                                }}
                              >
                                Accept
                              </button>
                            </>
                          ) : null}
                        </div> */}
                      <div className="card p-12">
                        <Link to={"/order_details/" + ord_ele.id}>
                          <div className="d-flex mb-1">
                            <div>
                              <div className="mb-1 fw-500">
                                {ord_ele?.customer?.name}
                              </div>
                            </div>
                            <div className="ms-auto text-end">
                              <div className="mb-1">
                                {/* {format(ord_ele?.timeago, "en_US")} */}
                                {ord_ele?.date}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex border-bottom pb-2 mb-2">
                            <div className="mb2">
                              <span
                                className={
                                  ord_ele?.status == "Order Placed" ||
                                    ord_ele?.status == "Seller Accepted"
                                    ? "badge-1 me-2 badge-blue"
                                    : ord_ele?.status == "Seller Rejected"
                                      ? "badge-1 me-2 badge-red"
                                      : ord_ele?.status == "Auto Cancelled"
                                        ? "badge-1 me-2 badge-red-2"
                                        : ord_ele?.status == "Completed"
                                          ? "badge-1 me-2 badge-green"
                                          : ord_ele?.status == "Delivery Partner Accepted"
                                            ? "badge-1 me-2 badge-blue"
                                            : "badge-1 me-2 badge-red-2"


                                }
                              >
                                {ord_ele?.status}
                              </span>

                              <span className="fs-12">
                                {ord_ele?.delivery_type == "Hyper Local" && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "31b719f813a5801b0b9e68653ff6df89.svg"
                                    }
                                    className="img-fluid me-1"
                                    width="14"
                                  />
                                )}
                                {ord_ele?.delivery_type}
                              </span>
                            </div>
                            {ord_ele.dm_order && (
                              <span className="badge-1 me-2 badge-blue ms-2">
                                Demo
                              </span>
                            )}
                            <div className="ms-auto text-end">
                              <div>
                                ID:{" "}
                                <span className="fw-500">{ord_ele?.id}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex border-bottom pb-2 mb-2">
                            <div>
                              <div className="mb-1 fw-500">
                                {ord_ele?.seller?.brand_name}
                              </div>
                              <div className="mb-1 fw-500">
                                {ord_ele?.seller?.outlet?.name}
                              </div>
                            </div>

                            <div className="ms-auto">{ord_ele?.sector}</div>
                          </div>
                          {ord_ele.sector_id == "4" ? (
                            <div className="">
                              <div className="d-flex pb-3">
                                <img
                                  src={require("../../assets/icon/up-arrow.png")}
                                  className="icon-18px"
                                />
                                <div className="ps-2">
                                  <p className="text-dark pb-1">Pickup</p>
                                  <p className="text-dark fw-bold">
                                    {
                                      ord_ele?.customer?.pickup_address
                                        ?.firstname
                                    }{" "}
                                    {
                                      ord_ele?.customer?.pickup_address
                                        ?.lastname
                                    }
                                  </p>
                                  {ord_ele?.customer?.pickup_address
                                    ?.address ? (
                                    <p className="pt-1">
                                      {
                                        ord_ele?.customer?.pickup_address
                                          ?.address
                                      }
                                    </p>
                                  ) : (
                                    <p className="pt-1">
                                      {
                                        ord_ele?.customer?.pickup_address
                                          ?.map_address
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="d-flex">
                                <img
                                  src={require("../../assets/icon/down.png")}
                                  className="icon-18px"
                                />
                                <div className="ps-2">
                                  <p className="text-dark pb-1">Delivery</p>
                                  <p className="text-dark fw-bold">
                                    {
                                      ord_ele?.customer?.delivery_address
                                        ?.firstname
                                    }{" "}
                                    {
                                      ord_ele?.customer?.delivery_address
                                        ?.lastname
                                    }
                                  </p>
                                  {ord_ele?.customer?.delivery_address
                                    ?.address ? (
                                    <p className="pt-1">
                                      {
                                        ord_ele?.customer?.delivery_address
                                          ?.address
                                      }
                                    </p>
                                  ) : (
                                    <p className="pt-1">
                                      {
                                        ord_ele?.customer?.delivery_address
                                          ?.map_address
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <ProductList
                              products={ord_ele?.ordered_products}
                              diet_type={products_diet_type}
                            />
                          )}
                          <div className="border-top">
                            <div className="d-flex align-items-center w-100 pt-2 mb-2">
                              <div>
                                <span className="me-2 fw-500 text-muted">
                                  Total Bill
                                </span>
                                {ord_ele?.cod_order == true &&
                                  ord_ele?.cod_payment_status == true ? (
                                  <span className="badge-2">COD Paid</span>
                                ) : ord_ele?.cod_order == true ? (
                                  <span className="badge-2">COD</span>
                                ) : ord_ele?.payment_type_id == "1" &&
                                  ord_ele?.order_completed == false ? (
                                  <span className="badge-2">Online</span>
                                ) : (
                                  ord_ele?.payment_type_id == "1" &&
                                  ord_ele?.order_completed == true && (
                                    <span className="badge-2">Paid</span>
                                  )
                                )}
                              </div>
                              <div className="ms-auto">
                                <span className="fw-300 fs-16">&#8377;</span>{" "}
                                <span className="fw-500">
                                  {ord_ele?.total_amount_payable}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div>
                          {ord_ele?.shipments.length > 0
                            ? ord_ele?.shipments[0]?.super_delivery_order[0]
                              ?.delivery_partner[0]?.delivery_partner?.name
                              ? ord_ele?.shipments[0]?.super_delivery_order[0]?.delivery_partner.map(
                                (dele_ele, i) =>
                                  dele_ele?.delivery_partner?.name &&
                                  dele_ele?.currently_working && (
                                    <div className="border-top pt-2">
                                      <div className="d-flex ">
                                        {dele_ele.delivery_partner.profile ? (
                                          <img
                                            src={
                                              dele_ele.delivery_partner
                                                .profile
                                            }
                                            alt="16.a1d4f3f3"
                                            className="rounded-float-start"
                                            height="34"
                                            width="34"
                                            style={{
                                              borderRadius: "50px",
                                            }}
                                          />
                                        ) : (
                                          <i className="fa fa-user m-2"></i>
                                        )}
                                        <div className="ms-2">
                                          <p className="extra-small text-gray pb-1">
                                            {
                                              dele_ele.delivery_partner
                                                .brand_name
                                            }
                                          </p>

                                          <p>
                                            <span className="fw-bold text-black ">
                                              {dele_ele.delivery_partner.name}
                                            </span>
                                            {/* "15px 50px 30px 5px" */}
                                            <span className="px-1"></span>
                                            <span
                                              className={
                                                ord_ele.status ==
                                                  "Rejected" ||
                                                  ord_ele.status == "Cancelled"
                                                  ? "text-red v-small ps-0"
                                                  : ord_ele.status ==
                                                    "Order Completed" ||
                                                    ord_ele.status ==
                                                    "Completed"
                                                    ? "text-green v-small ps-0"
                                                    : "text-prime v-small ps-0"
                                              }
                                            >
                                              {dele_ele?.status}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="d-flex align-items-center ms-auto">
                                          <div
                                            className=""
                                            style={{ cursor: "pointer" }}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              call_del_func(
                                                ord_ele.id,
                                                "delivery_partner",
                                                dele_ele.id
                                              );
                                            }}
                                          >
                                            <img
                                              src={require("../../assets/icon/call.png")}
                                              className="logo-img"
                                            />
                                          </div>

                                          <div className="end">
                                            <div className="ms-auto d-flex">
                                              <div
                                                className="center  w-50 cursor"
                                                onClick={() => {
                                                  // SetMapDriverModal({
                                                  //   is_open: true,
                                                  //   lat: ele.delivery[0]?.hyperlocal_pickup
                                                  //     ?.pickup_lat,
                                                  //   lon: ele.delivery[0]?.hyperlocal_pickup
                                                  //     ?.pickup_lng,
                                                  //   id: ele.delivery[0].id,
                                                  // });
                                                  // {console.log(order_data?.id)}
                                                  SetOrderTrackingModal(
                                                    (prevalue: any) => {
                                                      prevalue.is_open = true;
                                                      prevalue.delivery_index = i;
                                                      prevalue.order_details =
                                                        order_data[ord_index];

                                                      prevalue.delivery_id =
                                                        ord_ele?.shipments[0].id;
                                                      prevalue.shipment_index = 0;
                                                      return { ...prevalue };
                                                    }
                                                  );
                                                }}
                                              >
                                                {/* <Entypo
                                            
                                                name="location-pin"
                                                size={14}
                                                color="#666"
                                              /> */}
                                                <div className="ms-4">
                                                  <img
                                                    src={require("../../assets/icon/map_or.png")}
                                                    className="delivery"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* {shipment.hyperlocal_pickup
                                            .delivery_partner_mobile && (
                                            <>
                                              <div
                                                className="d-flex cursor px-3 border-right"
                                                onClick={() => {
                                                  del_partner_number(
                                                    ele.id,
                                                    shipment.hyperlocal_pickup
                                                      .delivery_partner_mobile_delivery_id,ele.brand_id,ele.outlet_id
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={require("../../../assets/icon/call.png")}
                                                  className="logo-img"
                                                />
                                              </div>
                                              <div
                                                className="m-2"
                                                onClick={() => {
                                                  let send_data = {
                                                    order_id: ele.id,
                                                    chatto : "delivery_partner",
                                                    brand_id:ele.brand_id,
                                                    outlet_id:ele.outlet_id,
                                                    delivery_id : shipment.hyperlocal_pickup
                                                    .delivery_partner_mobile_delivery_id
                                                  };
                                                  chat_pickup(send_data);
                                                }}
                                                style={{width: 22}}
                                              >
                                                <img
                                                  src={
                                                    AppConfig.CDN_Media_url +
                                                    "0de0411aecb1f133a79605154a7e62b6.svg"
                                                  }
                                                  className="img-fluid"
                                                  
                                                />
                                              </div>
                                            </>
                                          )}
    
                                          {shipment.live_tracking == true && (
                                            <div className="cursor px-3">
                                              <img
                                                onClick={() => {
                                                  SetOrderTrackingModal(
                                                    (prevalue: any) => {
                                                      prevalue.is_open = true;
                                                      prevalue.delivery_index =
                                                      shipment_i;
                                                      prevalue.delivery_id =
                                                        shipment.id;
                                                        prevalue.brand_id =
                                                        ele.brand_id;
                                                        prevalue.outlet_id =
                                                        ele.outlet_id;
                                                      prevalue.order_data = ele;
                                                      return {
                                                        ...prevalue,
                                                      };
                                                    }
                                                  );
                                                }}
                                                src={require("../../../assets/icon/map.png")}
                                                className="delivery"
                                              />
                                            </div>
                                          )} */}
                                        </div>
                                      </div>

                                      <div className="d-flex align-items-center mt-2">
                                        <div className="d-flex alig-items-center">
                                          <div className="border-left px-1">
                                            {/* {shipment.hyperlocal_pickup.otp} */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                              )
                              : ord_ele?.order_status_id != 4 && (
                                <div className="border p-2 text-muted br-4">
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "0b90c59787ac497d0b292aaa9f052f83.svg"
                                    }
                                    className="img-fluid me-2"
                                    width="14"
                                  />
                                  We will assign one delivery partner soon
                                </div>
                              )
                            : ""}
                        </div>
                        <div>
                          {ord_ele?.pickup_drop.length > 0
                            ? ord_ele?.pickup_drop[0]?.delivery_partner[0]?.delivery_partner?.name
                              ? ord_ele?.pickup_drop[0]?.delivery_partner.map(
                                (dele_ele, i) =>
                                  dele_ele?.delivery_partner?.name &&
                                  dele_ele?.currently_working && (
                                    <div className="border-top pt-2">
                                      <div className="d-flex ">
                                        {dele_ele.delivery_partner.profile ? (
                                          <img
                                            src={
                                              dele_ele.delivery_partner
                                                .profile
                                            }
                                            alt="16.a1d4f3f3"
                                            className="rounded-float-start"
                                            height="34"
                                            width="34"
                                            style={{
                                              borderRadius: "50px",
                                            }}
                                          />
                                        ) : (
                                          <i className="fa fa-user m-2"></i>
                                        )}
                                        <div className="ms-2">
                                          <p className="extra-small text-gray pb-1">
                                            {
                                              dele_ele.delivery_partner
                                                .brand_name
                                            }
                                          </p>

                                          <p>
                                            <span className="fw-bold text-black ">
                                              {dele_ele.delivery_partner.name}
                                            </span>
                                            {/* "15px 50px 30px 5px" */}
                                            <span className="px-1"></span>
                                            <span
                                              className={
                                                ord_ele.status ==
                                                  "Rejected" ||
                                                  ord_ele.status == "Cancelled"
                                                  ? "text-red v-small ps-0"
                                                  : ord_ele.status ==
                                                    "Order Completed" ||
                                                    ord_ele.status ==
                                                    "Completed"
                                                    ? "text-green v-small ps-0"
                                                    : "text-prime v-small ps-0"
                                              }
                                            >
                                              {dele_ele?.status}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="d-flex align-items-center ms-auto">
                                          <div
                                            className=""
                                            style={{ cursor: "pointer" }}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              call_del_func(
                                                ord_ele.id,
                                                "delivery_partner",
                                                dele_ele.id
                                              );
                                            }}
                                          >
                                            <img
                                              src={require("../../assets/icon/call.png")}
                                              className="logo-img"
                                            />
                                          </div>

                                          <div className="end">
                                            <div className="ms-auto d-flex">
                                              <div
                                                className="center  w-50 cursor"
                                                onClick={() => {
                                                  // SetMapDriverModal({
                                                  //   is_open: true,
                                                  //   lat: ele.delivery[0]?.hyperlocal_pickup
                                                  //     ?.pickup_lat,
                                                  //   lon: ele.delivery[0]?.hyperlocal_pickup
                                                  //     ?.pickup_lng,
                                                  //   id: ele.delivery[0].id,
                                                  // });
                                                  // {console.log(order_data?.id)}
                                                  SetOrderTrackingModal(
                                                    (prevalue: any) => {
                                                      prevalue.is_open = true;
                                                      prevalue.delivery_index = 0;
                                                      prevalue.order_details =
                                                        order_data[ord_index];

                                                      prevalue.delivery_id =
                                                        dele_ele.id;
                                                      prevalue.shipment_index = 0;
                                                      return { ...prevalue };
                                                    }
                                                  );
                                                }}
                                              >
                                                {/* <Entypo
                                            
                                                name="location-pin"
                                                size={14}
                                                color="#666"
                                              /> */}
                                                <div className="ms-4">
                                                  <img
                                                    src={require("../../assets/icon/map_or.png")}
                                                    className="delivery"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* {shipment.hyperlocal_pickup
                                            .delivery_partner_mobile && (
                                            <>
                                              <div
                                                className="d-flex cursor px-3 border-right"
                                                onClick={() => {
                                                  del_partner_number(
                                                    ele.id,
                                                    shipment.hyperlocal_pickup
                                                      .delivery_partner_mobile_delivery_id,ele.brand_id,ele.outlet_id
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={require("../../../assets/icon/call.png")}
                                                  className="logo-img"
                                                />
                                              </div>
                                              <div
                                                className="m-2"
                                                onClick={() => {
                                                  let send_data = {
                                                    order_id: ele.id,
                                                    chatto : "delivery_partner",
                                                    brand_id:ele.brand_id,
                                                    outlet_id:ele.outlet_id,
                                                    delivery_id : shipment.hyperlocal_pickup
                                                    .delivery_partner_mobile_delivery_id
                                                  };
                                                  chat_pickup(send_data);
                                                }}
                                                style={{width: 22}}
                                              >
                                                <img
                                                  src={
                                                    AppConfig.CDN_Media_url +
                                                    "0de0411aecb1f133a79605154a7e62b6.svg"
                                                  }
                                                  className="img-fluid"
                                                  
                                                />
                                              </div>
                                            </>
                                          )}
    
                                          {shipment.live_tracking == true && (
                                            <div className="cursor px-3">
                                              <img
                                                onClick={() => {
                                                  SetOrderTrackingModal(
                                                    (prevalue: any) => {
                                                      prevalue.is_open = true;
                                                      prevalue.delivery_index =
                                                      shipment_i;
                                                      prevalue.delivery_id =
                                                        shipment.id;
                                                        prevalue.brand_id =
                                                        ele.brand_id;
                                                        prevalue.outlet_id =
                                                        ele.outlet_id;
                                                      prevalue.order_data = ele;
                                                      return {
                                                        ...prevalue,
                                                      };
                                                    }
                                                  );
                                                }}
                                                src={require("../../../assets/icon/map.png")}
                                                className="delivery"
                                              />
                                            </div>
                                          )} */}
                                        </div>
                                      </div>

                                      <div className="d-flex align-items-center mt-2">
                                        <div className="d-flex alig-items-center">
                                          <div className="border-left px-1">
                                            {/* {shipment.hyperlocal_pickup.otp} */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                              )
                              : ord_ele?.order_status_id != 4 && (
                                <div className="border p-2 text-muted br-4">
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "0b90c59787ac497d0b292aaa9f052f83.svg"
                                    }
                                    className="img-fluid me-2"
                                    width="14"
                                  />
                                  We will assign one delivery partner soon
                                </div>
                              )
                            : ""}
                        </div>

                        <div className="border-top pt-2">
                          <div className="d-flex">
                            <div>
                              Order ID:{" "}
                              <span className="fw-500">{ord_ele?.order_id}</span>
                            </div>
                            {ord_ele.sector_id == "4" ? 
                            <a
                            className = "ms-auto"
  href={`https://www.google.com/maps/dir/?api=1&origin=${ord_ele?.customer?.pickup_address?.lat},${ord_ele?.customer?.pickup_address?.lng}&destination=${ord_ele?.customer?.delivery_address?.lat},${ord_ele?.customer?.delivery_address?.lng}&travelmode=driving`}
  target="_blank" // Opens the link in a new tab
  rel="noopener noreferrer" // Improves security for external links
>
                            <button
                              type="button"
                              className="btn btn-prime btn-small px-3 ms-auto"
                              // onClick={() => {
                              //   openPickDropMap(
                              //     ord_ele?.seller?.outlet?.lat,
                              //     ord_ele?.seller?.outlet?.lng,
                              //     ord_ele?.customer?.delivery_address?.lat,
                              //     ord_ele?.customer?.delivery_address?.lng
                              //   );
                              // }}
                            >
        
                              <span className="small">
                                Pickup & Drop Location
                              </span>
                            </button>
                            </a>
:  <a
className = "ms-auto"
href={`https://www.google.com/maps/dir/?api=1&origin=${ord_ele?.seller?.outlet?.lat},${ord_ele?.seller?.outlet?.lng}&destination=${ord_ele?.customer?.delivery_address?.lat},${ord_ele?.customer?.delivery_address?.lng}&travelmode=driving`}
target="_blank" // Opens the link in a new tab
rel="noopener noreferrer" // Improves security for external links
>
<button
  type="button"
  className="btn btn-prime btn-small px-3 ms-auto"
  // onClick={() => {
  //   openPickDropMap(
  //     ord_ele?.seller?.outlet?.lat,
  //     ord_ele?.seller?.outlet?.lng,
  //     ord_ele?.customer?.delivery_address?.lat,
  //     ord_ele?.customer?.delivery_address?.lng
  //   );
  // }}
>

  <span className="small">
    Pickup & Drop Location
  </span>
</button>
</a>}
                          </div>

                          <div>
                            Order Item ID::{" "}
                            <span className="fw-500">{ord_ele?.id}</span>
                          </div>
                          {ord_ele?.shipments.length > 0 && (
                            <div>
                              Shipment ID:{" "}
                              <span className="fw-500">
                                {ord_ele?.shipments.map(
                                  (ele, i) => ele.id + " "
                                )}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className=" card px-2 mb-2 ">
                          <OwnAccordian heading={"Timeline Details"}>
                            <ul className="delivery_status_vertical w-100 pt-2">
                              {ord_ele?.order_timeline?.map((t_ele: any) => (
                                <li>
                                  <time className="">
                                    {t_ele.time_formatted}
                                  </time>
                                  <div className="timeline ">
                                    <div className="d-flex align-items-center">
                                      {t_ele.type == "delivery_partner" ? (
                                        <img
                                          src={require("../../assets/icon/delivery-boy.png")}
                                          className="w-10 mx-1"
                                          height="16"
                                          width="16"
                                        />
                                      ) : null}
                                      {t_ele.type == "shipment" ? (
                                        <img
                                          src={require("../../assets/icon/cutlery.png")}
                                          className="w-10 mx-1"
                                          height="16"
                                          width="16"
                                        />
                                      ) : null}
                                      <p className="text-gray small ps-1">
                                        {t_ele.status}
                                      </p>

                                      <div className="w-20 center ms-auto">
                                        {t_ele.hasOwnProperty("lat") && (
                                          <button
                                            type="button"
                                            className="btn btn-prime btn-small px-3"
                                            onClick={() => {
                                              openMap(t_ele?.lat, t_ele?.lng);
                                            }}
                                          >
                                            <span className="small">Map</span>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </OwnAccordian>
                        </div>

                        <button className="btn btn-primary me-2"
                          onClick={() =>
                            SetUserModal((prevValue: any) => ({
                              ...prevValue,
                              is_open: true,
                              id: ord_ele.customer.user_id
                            }))}>View Customer User Details</button>
                      </div>
                    </div>
                  ))}
                </div>
           
          </InfiniteScroll>
        </div>
      </div> : <div className="center p-3">
                  <div className="card p-3 w-100">
                    <div className="w-100 text-center">
                      <img
                        src={require("../../assets/img/not-found.png")}
                        className="w-20"
                      />
                    </div>
                    <p className="center pt-3">No Orders data</p>
                  </div>
                </div>
            
                  ) : (
                    <div className="row">
                      {[1, 2, 3, 4, 5, 6].map(() => (
                        <div className="col-md-4 col-12 mb-3">
                          <OrderSkeleton />
                        </div>
                      ))}
                    </div>
                  )}

      <ViewOutletOrder
        open={view_modal.is_open}
        data={view_modal}
        close={(data: any) => {
          ViewModalOnClose(data);
        }}
      />

      <TrackOrder
        open={view_track_modal.is_open}
        data={view_track_modal}
        close={(data: any) => {
          ViewTrackModalOnClose(data);
        }}
      />

      <ChooseBrandOutletModal
        open={seller_modal.is_open}
        close={(data: any) => {
          setSellerModal({
            is_open: false,
          });
          if (data.action == "save") {
            // setSeller(data.data);
            console.log(data.data);
            SetBrandName(data.data.brand_name)
            SetOutletName(data.data.outlet_name)
            SetBrandId(data.data.brand_id);
            SetOutletId(data.data.outlet_id)
            SetPageNo(1);
            let pass_data = {
              order_status: order_status,
              seller_brand_id: data.data.brand_id,
              seller_outlet_id: data.data.outlet_id,
              sort: "desc",
              page_no: 1,
              demo: isOn,

            };
            get_orders(pass_data, filter_data);

          }
        }}
      />


      <ModalUserDetails
        open={user_modal.is_open}
        id={user_modal.id}
        close={(data: any) => {
          SetUserModal({
            is_open: false,
            id: "",
          });
        }}
      />



      {/* <ChooseBrandOutletModal
                open={brand_modal.is_open}
                close={(data: any) => {
                    setBrandModal({
                        is_open: false,
                        data: {},
                    });
                    if (data.action == "save") {
                        setSeller(data.data);
                    }
                }}
            /> */}


      {order_data && order_tracking_modal.is_open ? (
        <ModalOrderTracking
          open={order_tracking_modal.is_open}
          delivery_id={order_tracking_modal.delivery_id}
          delivery_index={order_tracking_modal.delivery_index}
          order_details={order_tracking_modal.order_details}
          shipment_index={order_tracking_modal.shipment_index}
          close={() => {
            SetOrderTrackingModal((prevalue: any) => {
              prevalue.is_open = false;
              prevalue.delivery_index = "";
              prevalue.shipment_index = "";
              prevalue.order_details = "";

              prevalue.delivery_id = "";
              return { ...prevalue };
            });
          }}
        />
      ) : null}
    </div>
  );
}
